//console.log('>> Home page');
import * as AOS from 'aos/dist/aos.js';
//alpinejs
import Alpine from 'alpinejs'
window.Alpine = Alpine


Alpine.start()

AOS.init();

window.addEventListener('scroll', function () {
  var body = document.body;
  if (window.scrollY > 0) {
    body.classList.add('scrolled');
  } else {
    body.classList.remove('scrolled');
  }
});



// Navbar Button toggle
const menuToggle = document.getElementById('menu-toggle');
const navbar = document.getElementById('navbar__nav');

menuToggle.addEventListener('click', () => {
  navbar.classList.toggle('open');
});
// ScrollTo
document
  .querySelectorAll('a.scroll-to')
  .forEach(trigger => {
    trigger.onclick = function (e) {
      e.preventDefault();

      let hash = this.getAttribute('href').split('#')[1];
      let target = document.getElementById(hash);

      if (!target) {
        window.location.href = this.href;
        return;
      }

      let headerOffset = parseInt(this.getAttribute('data-header-offset')) || 175;
      let elementPosition = target.offsetTop;
      let offsetPosition = elementPosition - headerOffset;
      //console.log(offsetPosition);
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    };
  });
// Navbar Dropdown

document.addEventListener('DOMContentLoaded', function () {
  var dropdownLinks = document.querySelectorAll('[data-dropdown]');
  var dropdownLinksInner = document.querySelectorAll('.dropdown-link');
  //var dropdownIndicator = document.querySelectorAll('.dropdown-indicator');

  dropdownLinks.forEach(function (link) {
    link.addEventListener('click', function () {
      var dropdownId = link.getAttribute('data-dropdown');
      var dropdownMenu = document.getElementById(dropdownId);

      //dropdownIndicator.classList.toggle('active');

      if (dropdownMenu) {
        dropdownMenu.classList.toggle('hidden');
      }

      dropdownLinksInner.forEach(function (link) {
        link.addEventListener('click', function () {
          dropdownMenu.classList.toggle('hidden');
        });
      });

    });
  });
});

//Videoplayer
/*
document.addEventListener('DOMContentLoaded', function() {
  // Elemente auswählen
  const playButton = document.querySelector('#play');
  const videoPlayer = document.querySelector('#player');

  // Click-Event für den Play-Button hinzufügen
  playButton.addEventListener('click', function() {
      if (videoPlayer.paused) {
          // Wenn das Video pausiert ist, starte es
          videoPlayer.play();
          playButton.innerHTML = '<<i class="fa-solid fa-pause"></i>> Pause'; // Optional: Ändere den Button-Text
      } else {
          // Wenn das Video läuft, pausiere es
          videoPlayer.pause();
          playButton.innerHTML = '<i class="fa-solid fa-play"></i> Play'; // Optional: Ändere den Button-Text zurück
      }
  });
});
*/
